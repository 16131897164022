import React from "react"
import { Container } from "reactstrap"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { Banner, TeamCollection } from "@igloonet-web/shared-ui"
import { useActiveEmployees } from "../hooks/use-active-employees"
import ProfileImage from "../components/profile-image"

export default function Tym() {
  const employees = useActiveEmployees()

  const divisions = [
    ["marketing", "Markeťáci"],
    ["vyvoj", "Vývoj"],
    ["hosting", "Hosting"],
    ["administrativa", "Administrativa"],
    ["obchod", "Obchod"],
  ]

  return (
    <Layout>
      <Helmet>
        <title>Tučňáci v igloo | igloonet</title>
        <meta
          name="description"
          content="Kdo se postará o vaše facebookové kampaně a s kým budete řešit hosting? Seznamte se s úžasným týmem igloonetu."
        />
      </Helmet>
      <Banner>
        <h1>Tučňáci v igloo</h1>
        <p>Poznej nás</p>
      </Banner>

      <Container className="text-center">
        <TeamCollection
          imageTag={ProfileImage}
          teamMembers={employees.filter((e) => e.teams.includes("vedeni"))}
          title="Vedení"
        />

        {divisions.map((division, i) => (
          <TeamCollection
            key={i}
            imageTag={ProfileImage}
            teamMembers={employees.filter((e) => e.division === division[0])}
            title={division[1]}
          />
        ))}
      </Container>
    </Layout>
  )
}
